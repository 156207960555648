<template>
  <div class="wrap">
    <Navbar :title="$t('page.verifyPhone')" />
    <div class="br"></div>
    <Field
      v-model="phone"
      disabled
      label-width="fit-content"
      :label="$t('My.PersonalInformation.Password.phone')"
      :placeholder="$t('My.PersonalInformation.Password.NewPhone')"
    />
    <div class="br"></div>
    <Field
      v-model="code"
      label-width="fit-content"
      :label="$t('phoneVerify.label.code')"
      :placeholder="$t('phoneVerify.placeholder.code')"
    >
      <template #button>
        <Button class="send" size="small" type="primary" @click="send">
          {{ !!stop ? stop : $t("phoneVerify.btn.send") }}
        </Button>
      </template>
    </Field>
    <div class="br"></div>
    <Button class="confirm" @click="confirm">{{
      $t("phoneVerify.btn.confirm")
    }}</Button>
  </div>
</template>

<script>
import Navbar from "@/components/JNav";
import { Field, Button, Toast } from "vant";
import { post, dealError } from "@/utils/newRequest";
export default {
  components: { Navbar, Field, Button, Toast },
  data() {
    return {
      phone: "",
      stop: 0,
      sendInterval: null,
      code: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    async send() {
      if (!!this.stop) return;
      if (!this.phone) {
        this.$toast(this.$t("My.PersonalInformation.Password.PhoneNew"));
        return;
      }
      try {
        const res = await post("/users/sms");
        console.log(res);
        this.stop = 60;
        this.sendInterval = setInterval(() => {
          this.stop--;
          if (!this.stop) {
            clearInterval(this.sendInterval);
          }
        }, 1000);
      } catch (error) {
        dealError(error);
      }
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          this.phone = res.userphone;
          Toast.clear();
        },
        (err) => {
          Toast.clear();
          this.$toast(err);
        }
      );
    },
    async confirm() {
      if (!this.phone) {
        this.$toast(this.$t("My.PersonalInformation.Password.PhoneNew"));
        return;
      }
      if (!this.code) {
        this.$toast(this.$t("phoneVerify.placeholder.code"));
        return;
      }
      try {
        const res = await post("/users/login", {
          userphone: this.phone,
          code: this.code,
        });
        console.log(res);
      } catch (error) {
        if (typeof error === "string") {
          this.$toast(error);
        } else if (!!error.msg) {
          this.$toast(error.msg);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
@comBG: linear-gradient(#093958, #022c47);
.wrap {
  min-height: 100vh;
  // background: #f5f5f5;
  overflow: hidden;
  background: var(--bg);

  .br {
    height: 10px;
    // background: #f5f5f5;
    background: var(--bg);
  }

  .send {
    border-radius: 4px;
    background: var(--theme);
    border: none;
  }

  /deep/ .van-field__label {
    display: flex;
    align-items: center;
  }
  .van-cell {
    background: @comBG;
    &::after {
      border: none;
    }
  }
  /deep/ .van-field__label {
    color: var(--grey);
  }
  /deep/ .van-field__control:disabled {
    color: var(--theme);
    -webkit-text-fill-color: var(--theme);
  }
  /deep/ .van-field__control {
    color: var(--theme);
  }

  .confirm {
    width: 90vw;
    margin-left: 5vw;
    background: var(--theme);
    color: var(--textColor);
    border-radius: 8px;
    margin-top: 20px;
  }
}
</style>